import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';

import Back from '@components/Back';
import ScrollUp from '@components/ScrollUp';
// import Feedback from '@components/Feedback';
import { ArticleWrapper } from '@rambler-help/components';
import { useRootStore } from '@stores/index';
import { RouteNames, RouteParameters } from '@rambler-help/shared';
import { SITE_TITLE } from '@constants/meta';
import { EArticleType } from '@rambler-help/shared';
import { EPageType } from '@rambler-help/shared';
import parserOptions from '@helpers/parserOptions';

import { styled } from '@linaria/react';
import {
  fontMontserrat,
  fontColorDefault,
  WrapperStyles,
  RightColumnStyles,
  styleLink
} from '@rambler-help/shared';

const ArticlePageStyled = styled.div`
  ${WrapperStyles}
  h1 {
    margin: 30px 0 20px;
    ${fontMontserrat}
    ${fontColorDefault}
    font-size: 26px;
    line-height: 30px;
    font-weight: 800;
  }
`;
const Content = styled.div`
  ${RightColumnStyles}
`;

const Article = observer((): ReactElement => {
  const { projects, sections, articles } = useRootStore();
  let title = '';
  let content = '';
  if (sections.activeSection && !sections.activeSubsection) {
    title = sections.activeSection?.title || '';
    content = sections.activeSection?.body || '';
  } else {
    title = articles?.article?.title || '';
    content = articles?.article?.body || '';
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>{`${title} — ${SITE_TITLE}`}</title>
      </Helmet>
      <ArticlePageStyled>
        <Content>
          <Back to="/">Все сервисы Рамблера</Back>
          <h1>{title}</h1>
          {articles.article?.type === EArticleType.Legal && (
            <ExpiredDocument
              started={articles.article.introduceTime}
              expired={articles.article.expireTime}
              nextId={articles.article.nextId}
              projectSlug={projects.project?.slug || ''}
            />
          )}
          <ArticleWrapper>
            <div id="content">
              {parse(content, parserOptions)}
            </div>
          </ArticleWrapper>
          <ScrollUp />
        </Content>
      </ArticlePageStyled>
      {/* <Feedback /> */}
    </React.Fragment>
  );
});

const ExpiredDocumentStyledComponent = styled.div`
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  color: #8D96B2;
  p {
    margin-bottom: 10px;
  }
  ${styleLink}
`;
const Expired = styled.div`
  position: relative;
  display: inline-block;
  height: 30px;
  margin-right: 10px;
  padding: 0 10px 0 40px;
  ${fontColorDefault}
  line-height: 30px;
  background-color: #ffeeed;
  border-radius: 5px;
  background-image: url('./images/attention.svg');
  background-repeat: no-repeat;
  background-position: 10px 5px;
`;
const Started = styled.div`
  display: inline-block;
  height: 30px;
  line-height: 30px;
`;

interface IExpiredDocumentProps {
  started?: Date | null;
  expired?: Date | null;
  nextId?: number;
  projectSlug: string;
}

const ExpiredDocument = observer((
  { started, expired, nextId, projectSlug }: IExpiredDocumentProps
): ReactElement => {
  const { layout } = useRootStore();
  const months = [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля',
    'августа', 'сентября', 'октября', 'ноября', 'декабря'
  ];
  let startedString;
  if (started) {
    const time = (
      typeof started === 'object'
        ? (started as Date)
        : (new Date(started as string))
    );
    startedString = time.getDate() + ' ' + months[time.getMonth()] + ' ' + time.getFullYear();
  }
  let expiredString;
  if (expired) {
    const time = (
      typeof expired === 'object'
        ? (expired as Date)
        : (new Date(expired as string))
    );
    expiredString = time.getDate() + ' ' + months[time.getMonth()] + ' ' + time.getFullYear();
  }
  return (
    <ExpiredDocumentStyledComponent>
      {(expired || started) && (
        <p>
          {expired && (
            <Expired>
              Документ утратил свою силу {expiredString}
            </Expired>
          )}
          {started && (
            <Started>
              {expired ? (
                <>Вступил в силу {startedString}</>
              ) : (
                <>Документ вступил в силу {startedString}</>
              )}
            </Started>
          )}
        </p>
      )}
      {nextId !== undefined && (
        <p>
          Перейти в <a
            href={(
              layout?.pageType === EPageType.LegalSeo
                ? RouteNames.USER_LEGAL_ARTICLE_PAGE
                : RouteNames.USER_COMMON_ARTICLE_PAGE
            ).replace(
              RouteParameters.ARTICLE_ID,
              nextId.toString()
            ).replace(
              RouteParameters.PROJECT_SLUG,
              projectSlug
            )}
          >действующий документ</a>
        </p>
      )}
    </ExpiredDocumentStyledComponent>
  );
});

export default Article;
