import React from 'react';
import { DOMNode, Element, HTMLReactParserOptions, domToReact } from 'html-react-parser';

import { Image } from '@rambler-help/components';
import { Attachment } from '@rambler-help/components';
import { Info } from '@rambler-help/components';
import { EXTENSION_REG_EX } from '@rambler-help/shared';
import { IMAGE_EXTENSIONS } from '@rambler-help/shared';

const parserOptions: HTMLReactParserOptions = {
  replace: (domNode: DOMNode) => {
    const node = domNode as Element;
    if (node.name) {
      switch (node.name) {
        case 'span': {
          return (
            node.attribs['data-color'] ? (
              <span style={{ color: node.attribs['data-color'] }}>
                {domToReact(node.children, parserOptions)}
              </span>
            ) : (
              <span>
                {domToReact(node.children, parserOptions)}
              </span>
            )
          );
        }
        case 'img': {
          return (
            <Image src={node.attribs.src} />
          );
        }
        case 'file': {
          const extension = node.attribs.src.match(EXTENSION_REG_EX);
          if (
            IMAGE_EXTENSIONS.includes(extension?.[0]?.replace('.', '') || '')
          ) {
            return (
              <Image src={node.attribs.src} />
            );
          } else {
            return (
              <Attachment
                isStatic
                url={node.attribs.src}
                title={node.attribs.title}
                extension={extension ? extension[0].replace('.', '') : ''}
                size={parseInt(node.attribs.size)}
              />
            );
          }
        }
        case 'info': {
          return (
            <Info style={{ marginBottom: '20px' }} type="info">
              {domToReact(node.children, parserOptions)}
            </Info>
          );
        }
        case 'hint': {
          return (
            <Info style={{ marginBottom: '20px' }} type="hint">
              {domToReact(node.children, parserOptions)}
            </Info>
          );
        }
        case 'importanthint': {
          return (
            <Info style={{ marginBottom: '20px' }} type="important-hint">
              {domToReact(node.children, parserOptions)}
            </Info>
          );
        }
        // @TODO: Почистить статьи от скриптов и удалить эту проверку.
        case 'head':
        case 'meta':
        case 'script': {
          return (null);
        }
        default: return (null);
      }
    }
  }
};

export default parserOptions;
